$(function () {
  const csrfToken = () => $('meta[name="csrf-token"]').attr("content");

  $(document).on("change", ".employee-enable-salary", function (e) {
    const path = $(this).closest("tr").data("update-path");
    const value = this.checked;

    $.ajax({
      url: path,
      method: "patch",
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      data: {
        employee: { enable_salary: value }
      }
    });
  });

  $(document).on("change", "#employee_pass_expiration_blank", function (e) {
    $("#employee_pass_expiration_1i").prop("disabled", this.checked);
    $("#employee_pass_expiration_2i").prop("disabled", this.checked);
    $("#employee_pass_expiration_3i").prop("disabled", this.checked);
  });
});
