$(function () {
  $(document).on('change', '.transaction-filter-year', function () {
    const entity = $('.transaction-filter-year').data('entity')

    let params = []
    if (entity) {
      params.push(`entity=${entity}`)
    }
    params.push(`year=${this.value}`)

    window.location.assign(`${window.location.pathname}?${params.join('&')}`)
  })
})
