$(function () {
  const csrfToken = () => $('meta[name="csrf-token"]').attr("content");

  const saveValue = input => {
    if (input.value == input.dataset.initial) {
      return;
    }

    const path = $(input).closest("tr").data("update-path");
    const field = input.dataset.field;
    const value = input.value;

    input.dataset.initial = value;

    $.ajax({
      url: path,
      method: "patch",
      headers: {
        "X-CSRF-Token": csrfToken()
      },
      data: {
        salary: { [field]: value }
      }
    });
  };

  const updateSums = () => {
    $(".salary-sum").each((i, cell) => {
      const index = $(cell).index();
      let newSum = 0;
      $(cell)
        .closest("table")
        .find("tbody tr")
        .each((j, tr) => {
          const value = $(tr).children().eq(index).find("input").val();
          newSum += Number(value.replace(",", ".") || 0);
        });
      $(cell).text(normalizeMoney(newSum.toString()));
    });
  };

  $(document).on("focus", ".salary-field input", function (e) {
    this.select();
  });

  $(document).on("keydown", ".salary-field input", function (e) {
    if (e.key === "Enter") {
      $(e.target).blur();
      const tableRowToFocus = e.shiftKey
        ? $(e.target).closest("tr").prev()
        : $(e.target).closest("tr").next();
      const index = $(e.target).closest("td").index();
      tableRowToFocus.children().eq(index).find("input").focus();
    }
  });

  $(document).on("input", ".salary-money-field input", function (e) {
    if (e.target.value) {
      $(e.target).closest(".salary-money-field").addClass("has-value");
    } else {
      $(e.target).closest(".salary-money-field").removeClass("has-value");
    }
  });

  $(document).on("blur", ".salary-field input", function (e) {
    const field = e.target.dataset.field;

    if (field.endsWith("_comment")) {
      saveValue(e.target);
    } else {
      e.target.value = normalizeMoney(e.target.value);
      saveValue(e.target);
      updateSums();
    }
  });

  const normalizeMoney = value => {
    if (!value) {
      return value;
    }

    value = value.replace(/[^\d,\.]/g, "").replace(",", ".");
    value = parseFloat(value);
    if (isNaN(value)) {
      value = 0;
    }
    value = value.toFixed(2).replace(".", ",");

    return value;
  };
});
